

















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const UserModule = namespace("UserModule");
@Component({
  components: {
    CardProfile: () => import("@/components/Card/CardProfile.vue"),
    TableApplications: () => import("@/components/Table/TableApplications.vue")
  }
})
export default class VProfile extends Vue {
  @UserModule.Getter
  public getName!: string;
  @UserModule.Getter
  public getPoints!: string;
  @UserModule.State
  public email!: string;
}
